import { tv, VariantProps } from '@/utils/tv'

export const cigarBarVariant = tv({
  slots: {
    base: ['bg-[#0F56AC]'],
    wrapper: ['max-w-7xl', 'mx-auto', 'px-6 lg:px-8'],
    container: [
      'flex justify-between items-center md:items-center gap-4 md:min-h-[64px]'
    ],
    image: ['hidden md:flex', 'w-auto h-[64px]'],
    textWrapper: ['my-3 mr-auto'],
    text: ['[&>b]:font-semibold [&>span>b]:text-white'],
    action: ['shrink-0 my-auto md:my-0']
  }
})

export type CigarBarVariantProps = VariantProps<typeof cigarBarVariant>
